<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :modal="true"
    :before-close="sendsmall"
  >
    <template slot="title">
      <div class="title">{{ activeName }}</div>
      <div class="title2" v-if="activeName == 'MINT'">
        Select your Pool and Mint its SAP.
      </div>
      <div class="title2" v-if="activeName == 'BURN'">
        Select your Pool and Burn its SAP.
      </div>
    </template>
    <el-row class="amount pc">
      <el-col :span="8">
        <div class="title">SAP</div>
        <el-select
          v-model="productSelect"
          @change="currencyChanged"
          class="selector"
        >
          <el-option
            v-for="(item, index) in productList"
            :key="index"
            :label="item.name"
            :value="index"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="10">Price: ${{ SAP_price.toFixed(2) }}</el-col>
          <!-- <el-col :span="12">{{ thisProduct.form }}</el-col> -->
          <el-col
            :span="14"
            v-if="
              thisProduct.type === 1 &&
              thisProduct.status === 1 &&
              activeName == 'MINT'
            "
          >
            share: {{ thisProduct.preAmountCurrent }} /
            {{ thisProduct.limit }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="15" :offset="1">
        <div class="title" v-if="activeName == 'MINT'">
          Amount you want to mint
        </div>
        <div class="title" v-if="activeName == 'BURN'">
          Amount you want to burn
        </div>
        <div class="title tradetittle" v-if="activeName == 'TRADE'">Trade</div>
        <el-input class="input" v-model="amount" @focus="amountElement = 1">
          <el-button slot="suffix" class="max" @click="calculateMax1">
            MAX
          </el-button>
        </el-input>
        <el-row class="price">
          <el-col :span="20">Balance: {{ sap_amount.toFixed(3) }} SAP</el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="amount mb">
      <el-col :span="15">
        <div class="title">SAP</div>
        <el-select
          v-model="productSelect"
          @change="currencyChanged"
          class="selector"
        >
          <el-option
            v-for="(item, index) in productList"
            :key="index"
            :label="item.name"
            :value="index"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="12">Price: ${{ SAP_price.toFixed(2) }}</el-col>
          <el-col :span="12">Open-end</el-col>
        </el-row>
      </el-col>
      <el-col :span="8" :offset="1">
        <div class="ntitle" v-if="activeName == 'MINT'">mint</div>
        <div class="ntitle" v-if="activeName == 'BURN'">burn</div>
        <el-input class="input" v-model="amount" @focus="amountElement = 1">
          <el-button slot="suffix" class="max" @click="calculateMax1">
            MAX
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row class="amount pc">
      <el-col :span="8">
        <div class="title" v-if="activeName == 'MINT'">Pay</div>
        <div class="title" v-if="activeName == 'BURN'">Receive</div>
        <el-select
          v-model="whiteListSelect"
          @change="optionChanged"
          class="selector"
        >
          <el-option
            v-for="(item, index) in whiteList"
            :key="index"
            :label="item.label"
            :value="index"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="20">
            Balance: ${{ whiteToken.amount.toFixed(2) }}
            {{ whiteToken.token }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="15" :offset="1">
        <div class="title" v-if="activeName == 'MINT'">Total cost</div>
        <div class="title" v-if="activeName == 'BURN'">Total receive</div>
        <el-input class="input" v-model="amount2" @focus="amountElement = 2">
          <el-button
            slot="suffix"
            class="max"
            @click="calculateMax1"
            v-if="activeName == 'MINT'"
          >
            MAX
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row class="amount mb">
      <el-col :span="15">
        <div class="title" v-if="activeName == 'MINT'">Pay</div>
        <div class="title" v-if="activeName == 'BURN'">Receive</div>
        <el-select
          v-model="whiteListSelect"
          @change="optionChanged"
          class="selector"
        >
          <el-option
            v-for="(item, index) in whiteList"
            :key="index"
            :label="item.token"
            :value="index"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="20">
            Balance: ${{ whiteToken.amount.toFixed(2) }}
            {{ whiteToken.token }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" :offset="1">
        <div class="ntitle" v-if="activeName == 'MINT'">cost</div>
        <div class="ntitle" v-if="activeName == 'BURN'">receive</div>
        <el-input class="input" v-model="amount2" @focus="amountElement = 2">
          <el-button
            slot="suffix"
            class="max"
            @click="calculateMax1"
            v-if="activeName == 'MINT'"
          >
            MAX
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row class="fee pc" :gutter="15">
      <el-col :span="11">
        <div class="title">Fee rate</div>
        <div class="unit">
          <p class="pbb">Mint Fee: {{ mintFee * 100 }} %</p>
          <p>Performance Fee: {{ performanceFee * 100 }} %</p>
        </div>
      </el-col>
      <el-col :span="13">
        <div class="unit unitmt20">
          <el-table :data="burnFeeTable">
            <el-table-column
              prop="period"
              label="Holding period"
              min-width="65%"
            ></el-table-column>
            <el-table-column label="Burn fee" min-width="35%">
              <template slot-scope="scope">
                {{ scope.row.fee * 100 }} %
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-row class="fee mb" :gutter="15">
      <el-col :span="24">
        <div class="title">Fee rate</div>
        <div class="unit">
          <p class="pbb">Mint Fee: {{ mintFee * 100 }} %</p>
          <p>Performance Fee: {{ performanceFee * 100 }} %</p>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="unit">
          <el-table :data="burnFeeTable">
            <el-table-column
              prop="period"
              label="Holding period"
              min-width="65%"
            ></el-table-column>
            <el-table-column label="Burn fee" min-width="35%">
              <template slot-scope="scope">
                {{ scope.row.fee * 100 }} %
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div>
      <el-row
        class="btn-box"
        v-if="thisProduct.type == 1 && thisProduct.status == 1"
      >
        <el-col :span="4" :offset="10">
          <el-button
            type="primary"
            class="btn"
            @click="actionPreMint()"
            v-loading="actionLoading"
            v-if="activeName == 'MINT'"
            :disabled="thisProduct.timelock == 1 && userAuthority != 1"
          >
            Pre Mint Now
          </el-button>
          <el-button
            type="primary"
            class="btn"
            @click="actionPreBurn()"
            v-loading="actionLoading"
            v-if="activeName == 'BURN'"
            :disabled="thisProduct.timelock == 1 && userAuthority != 1"
          >
            Pre Burn Now
          </el-button>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-button
            circle
            class="refresh"
            @click="refresh()"
            :class="{ loading: refreshing }"
          ></el-button>
        </el-col>
      </el-row>
      <el-row
        class="btn-box"
        v-else-if="thisProduct.type == 1 && activeName == 'MINT'"
      >
        <el-col :span="4" :offset="10">
          <el-button
            type="primary"
            class="btn"
            @click="actionPreClaim()"
            v-loading="actionLoading"
          >
            Claim Pre Mint
          </el-button>
        </el-col>
        <!-- <el-col :span="4" :offset="3">
            <el-button
              type="primary"
              class="btn"
              @click="actionMint()"
              v-loading="actionLoading"
              v-if="activeName == 'MINT'"
              :disabled="thisProduct.timelock == 1 "
            >
              Mint Now
            </el-button>
            <el-button
              type="primary"
              class="btn"
              @click="actionBurn()"
              v-loading="actionLoading"
              v-if="activeName == 'BURN'"
              :disabled="thisProduct.timelock == 1 "
            >
              Burn Now
            </el-button>
          </el-col> -->
        <el-col :span="4" :offset="1">
          <el-button
            circle
            class="refresh"
            @click="refresh()"
            :class="{ loading: refreshing }"
          ></el-button>
        </el-col>
      </el-row>
      <el-row class="btn-box" v-else>
        <el-col :span="10" :offset="7">
          <el-button
            type="primary"
            class="btn"
            @click="actionMint()"
            v-loading="actionLoading"
            v-if="activeName == 'MINT'"
            :disabled="thisProduct.timelock == 1 && userAuthority != 1"
          >
            Mint Now
          </el-button>
          <el-button
            type="primary"
            class="btn"
            @click="actionBurn()"
            v-loading="actionLoading"
            v-if="activeName == 'BURN'"
            :disabled="thisProduct.timelock == 1 && userAuthority != 1"
          >
            Burn Now
          </el-button>
        </el-col>
        <el-col :span="5" :offset="2">
          <el-button
            circle
            class="refresh"
            @click="refresh()"
            :class="{ loading: refreshing }"
          ></el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { getTokenPrice } from "@/assets/js/oracle";
import { SapInstruction } from "../assets/js/sapInstruction";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  getOraclePriceKey,
  getTokenPrice2,
  oraclePublicKey,
} from "../assets/js/oracleList";
import { getAssetValueSY, getAssetValue } from "@/assets/js/SYcalculate";
import {
  Connection,
  Transaction,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
} from "@solana/web3.js";
import {
  sendTransaction,
  signAndSendTransaction,
} from "../assets/js/sendTransaction";
import store from "../store";
import { burnFee } from "../assets/js/burnFee";
import { getTokenAccountMaxAmount } from "../assets/js/getBalance";
import { nullKey, npMintKey } from "../assets/js/stakingv2/staking";
import {
  getSYMemberKey,
  createSYMemberAcc,
  getSYMemberData,
  createSYMember,
  serverOwner,
  serverManager,
  SY_LEN,
  sapProgramId,
  syProgramId,
  checkSyLimit,
  preMintSy,
  preBurnSy,
  preClaimSy,
  getSyData,
  getSapData,
} from "../assets/js/sap/sap";
import { getMintInfo, rpcConfig, rpcUrl } from "../assets/js";
import { TOKENS, getMintData } from "../assets/js/token";
const { timeout, sleep } = require("@/assets/js/timeout");
const rateMul = 100000;

export default {
  name: "SAP Dialog",
  props: ["small", "userAuthority"],
  data() {
    return {
      dialogVisible: false,
      activeName: "MINT",
      tabs: ["MINT", "BURN", "TRADE", "FARM", "STAKING", "CLAIM"],
      productList: [{ name: "SAP" }],
      productSelect: 0,
      thisProduct: { name: "SAP" },
      width: "30%",
      selected: { amount: 0, deciamal: 9, price: 0.05, token: "SYP" },
      vaultAmount: 0,
      amount: 0,
      amount2: 0,
      SAP_price: 0,
      wallet: "",
      userSapAcc: "",
      sap_amount: 0,
      connection: new Connection(rpcUrl, rpcConfig),
      pubkey: "",
      memberKey: "",
      memberPDA: "",
      vendorPDA: "",
      owner: new PublicKey(serverOwner),
      tokens: TOKENS,
      whiteList: TOKENS,
      whiteListSelect: 0,
      whiteToken: { amount: 0, deciamal: 9, price: 0.05, token: "SYP" },
      tokenPrices: [],
      SYPrices: [],
      funds: [],
      syp_amount: 0,
      pdaNonce: 0,
      rLoading: "",
      sleep: "",
      refreshing: false,
      actionLoading: false,
      unstakeLoading: false,
      harvestLoading: false,
      sapProgramId: new PublicKey(sapProgramId),
      syProgramId: new PublicKey(syProgramId),
      mintFee: 0.01,
      performanceFee: 0.2,
      burnFeeTable: burnFee,
      initCuurrency: false,
      feeClass: {
        burnFee: 0,
        performanceFee: 0,
        sapCost: 100,
        feeDiscount: {
          staked: "",
          burn: 0,
          performance: 0,
          min: 0,
          max: 1,
        },
        userLastMintTime: 0,
        userTotalStaking: 0,
        syDay: 0,
      },
      amountElement: 1,
    };
  },
  mounted() {
    if (process.browser) {
      const autoRootFontSize = () => {
        if (document.documentElement.getBoundingClientRect().width <= 980) {
          this.width = "90%";
        } else {
          this.width = "80%";
        }
      };
      window.addEventListener("resize", autoRootFontSize);
      autoRootFontSize();
    }
    this.getfunds();
    this.initDialog();
    // this.getWalletData();
  },
  methods: {
    async getfunds() {
      let result = await this.$axios.post("/getFundList", { net: 0 });
      console.log("fund list", result);
      let tempData = [];
      let products = result.data.data;
      this.funds = products;
      for (let i = 0; i < products.length; i++) {
        if (products[i].type == 0) {
          let sapData;
          let res = await getSapData(this.connection, products[i].pubkey);
          if (res.code == 1) {
            sapData = res.data;
          } else {
            console.error("get sap data fail", res);
          }
          let newTemp = {
            whiteList: products[i].whiteList,
            vaultList: products[i].vaultList,
            tokenList: products[i].tokenList,
            pubkey: products[i].pubkey,
            owner: products[i].owner,
            net: products[i].net,
            name: products[i].name,
            initialized_date: products[i].initialized_date,
            init_value: products[i].init_value,
            init_price: products[i].init_price,
            mint: products[i].mint[0],
            mints: products[i].mint,
            type: products[i].type,
            timelock: products[i].timelock,
            mintFee: products[i].mintFee,
            burnFee: products[i].burnFee,
            performanceFee: products[i].performanceFee,
            ltype: 0,
            limit: 0,
            status: 0,
            feeAmount: sapData.fee,
            performanceFeeAmount: sapData.performanceFee,
          };
          let productOracleList = [];
          for (let j = 0; j < newTemp.tokenList.length; j++) {
            let ind = oraclePublicKey
              .map((e) => {
                return e.symbol;
              })
              .indexOf(newTemp.tokenList[j].name);
            if (ind != -1) {
              let oracleKey = oraclePublicKey[ind].price;
              productOracleList.push(oracleKey);
            } else {
              let nullAccount = "11111111111111111111111111111111";
              productOracleList.push(nullAccount);
            }
          }
          newTemp.oracleList = productOracleList;
          tempData.push(newTemp);
        } else if (products[i].type == 1) {
          let sapData;
          let res = await getSyData(this.connection, products[i].pubkey);
          if (res.code == 1) {
            sapData = res.data;
            console.log("sy data", sapData);
          } else {
            console.error("get sap data fail", res);
          }
          let preAmountCurrent = [0, 0];
          if (sapData.status == 1) {
            {
              let res = await getMintInfo(this.connection, products[i].mint[2]);
              if (res.code == 1) {
                preAmountCurrent[0] = res.data.supply / 10 ** res.data.decimals;
              }
            }
            {
              let res = await getMintInfo(this.connection, products[i].mint[3]);
              if (res.code == 1) {
                preAmountCurrent[1] = res.data.supply / 10 ** res.data.decimals;
              }
            }
          }
          for (let j = 1; j < SY_LEN + 1; j++) {
            let newTemp = {
              whiteList: products[i].whiteList,
              vaultList: products[i].vaultList,
              tokenList: products[i].tokenList,
              pubkey: products[i].pubkey,
              owner: products[i].owner,
              net: products[i].net,
              name: products[i].name + "-R" + j,
              initialized_date: products[i].initialized_date,
              init_value: products[i].init_value,
              init_price: products[i].init_price,
              mint: products[i].mint[j - 1],
              preMint: products[i].mint[j + 1],
              mints: products[i].mint,
              type: products[i].type,
              timelock: products[i].timelock,
              mintFee: products[i].mintFee,
              burnFee: products[i].burnFee,
              performanceFee: products[i].performanceFee,
              ltype: j,
              limit: sapData.limit[j - 1] / 10 ** 9,
              preAmountCurrent: preAmountCurrent[j - 1],
              status: sapData.status,
              feeAmount: sapData.fee,
              performanceFeeAmount: sapData.performanceFee,
            };
            let productOracleList = [];
            for (let q = 0; q < newTemp.tokenList.length; q++) {
              let ind = oraclePublicKey
                .map((e) => {
                  return e.symbol;
                })
                .indexOf(newTemp.tokenList[q].name);
              if (ind != -1) {
                let oracleKey = oraclePublicKey[ind].price;
                productOracleList.push(oracleKey);
              } else {
                let nullAccount = "11111111111111111111111111111111";
                productOracleList.push(nullAccount);
              }
            }
            newTemp.oracleList = productOracleList;
            tempData.push(newTemp);
          }
        }
      }
      this.productList = tempData;
      this.thisProduct = tempData[0];
      if (this.$store.state.walletConnectInfo) {
        this.currencyChanged();
      }
    },
    async getWalletData() {
      if (this.$store.state.walletConnectInfo) {
        // this.wallet = this.$store.state.walletConnectInfo.wallet
        this.publicKey = this.$store.state.walletConnectInfo.publicKey;
        // this.whiteToken = this.whiteList[0];
        let product = this.thisProduct;
        if (product.type == 1 && product.status == 1) {
          this.getSAPBalance(this.publicKey, this.thisProduct.preMint);
        } else {
          this.getSAPBalance(this.publicKey, this.thisProduct.mint);
        }
      }
    },
    determineLock() {
      if (this.userAuthority == 1) {
        return false;
      }
      if (this.thisProduct.timelock) {
        this.$notify({
          title: "Warning",
          message: "This pool is locked, please come back later.",
          type: "warning",
        });
      }
      return this.thisProduct.timelock;
    },
    async actionMint() {
      if (!this.actionLoading) {
        this.actionLoading = true;
        try {
          if (!this.determineLock()) {
            await this.goMint();
          }
        } catch (e) {
          this.$notify({
            title: "Error",
            message: "Mint error.",
            type: "error",
          });
          console.log(e);
        }
        this.actionLoading = false;
      }
    },
    async goMint() {
      // check token
      if (this.whiteToken.userAcc == "") {
        this.$notify({
          title: "Warning",
          message: "Please choose a valid token/pair account",
          type: "warning",
        });
        return;
      }
      // using data
      let mintProduct = this.thisProduct;
      let sapOwner = new PublicKey(serverOwner);
      let sapPool = new PublicKey(mintProduct.pubkey);
      let sapPoolMint = new PublicKey(mintProduct.mint);
      let sapPoolMints = mintProduct.mints;
      let wallet = this.$store.state.walletConnectInfo.wallet;
      let userWallet = this.$store.state.walletConnectInfo.publicKey;
      let userToken = this.whiteToken.userAcc;
      let tokenList = mintProduct.tokenList.map((e) => {
        return e.pubkey;
      });
      let tokenVualtList = mintProduct.vaultList;
      let oracleList = mintProduct.oracleList;
      let selectedPubkey = new PublicKey(this.whiteToken.pubkey);
      let amount = parseFloat(this.amount);
      let amountTran = amount * 10 ** 9;
      let preAmount = parseFloat(this.sap_amount);
      let sapPrice = parseFloat(this.SAP_price);
      // calculate get price;
      let getValue = parseFloat(this.amount2);
      let sapPrice2 = getValue / amount;
      console.log("amount", amount, "sap price", sapPrice);
      // check amount
      if (!(amount > 0)) {
        this.$notify({
          title: "Warning",
          message: "Invalid amount",
          type: "warning",
        });
        return;
      }
      // check price
      if (!(sapPrice > 0)) {
        this.$notify({
          title: "Warning",
          message: "Invalid sap price",
          type: "warning",
        });
        return;
      }
      // start
      // find user sap token account
      let sapAcc = null;
      {
        let res = await getTokenAccountMaxAmount(
          this.connection,
          wallet,
          sapPoolMint.toBase58()
        );
        if (res.code == 1) {
          sapAcc = res.data.acc;
        }
      }
      // check if has account, if not create account
      if (!sapAcc) {
        this.$alert(
          "Sypool.io will help you create a SAP token account first. Please authorize and wait a few seconds for account to become active. Please do not create multiple accounts.",
          "Alert",
          {
            confirmButtonText: "Ok",
            confirmButtonClass: "alert_btn_ok",
          }
        );
        let acc = await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          sapPoolMint,
          userWallet
        );
        let tx = new Transaction().add(
          Token.createAssociatedTokenAccountInstruction(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            sapPoolMint,
            acc,
            userWallet,
            userWallet
          )
        );
        let res = await signAndSendTransaction(
          this.connection,
          wallet,
          null,
          tx
        );
        if (res.code == 1) {
          sapAcc = acc;
        } else {
          this.$notify({
            title: "Warning",
            message: "Wait create SAP token account fail.",
            type: "warning",
          });
          console.error("create sap account error", res);
          return;
        }
        // wait for account
        let start = new Date();
        while (sapAcc.value.length == 0) {
          await sleep(3000);
          sapAcc =
            await this.$store.state.walletConnectInfo.connection.getTokenAccountsByOwner(
              this.$store.state.walletConnectInfo.publicKey,
              { mint: new PublicKey(mintProduct.mint) }
            );
          if (timeout(start, 180000)) {
            this.$notify({
              title: "Warning",
              message: "Wait create SAP token account time out.",
              type: "warning",
            });
            return;
          }
        }
      }
      this.userSapAcc = sapAcc;
      let userSap = sapAcc;
      // handle transaction
      if (mintProduct.type == 0) {
        let trxi = SapInstruction.createBuyInstruction(
          sapOwner,
          TOKEN_PROGRAM_ID,
          sapPool,
          sapPoolMint,
          userSap,
          userWallet,
          userToken,
          tokenList,
          tokenVualtList,
          oracleList,
          selectedPubkey,
          amountTran,
          mintProduct.mintFee * rateMul,
          this.sapProgramId
        );
        let transaction = new Transaction();
        transaction.add(trxi);
        let { blockhash } =
          await this.$store.state.walletConnectInfo.connection.getRecentBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = userWallet;
        // sign
        let signed = "";
        try {
          signed =
            await this.$store.state.walletConnectInfo.wallet.signTransaction(
              transaction
            );
        } catch (err) {
          this.$notify({
            title: "Error",
            message: "transaction cancelled",
            type: "error",
          });
          console.error("mint sign cancelled", err);
          return;
        }
        const temp = JSON.parse(JSON.stringify(signed));
        temp.signatures = signed.signatures.map((e) => {
          return {
            publicKey: e.publicKey.toBase58(),
            signature: !e.signature ? null : Array.from(e.signature),
          };
        });
        // server mint
        let res = await this.$axios.post("/mint", {
          data: JSON.stringify(temp),
          amount: amountTran,
          tokenPubkey: selectedPubkey.toBase58(),
          sapKey: sapPool.toBase58(),
          userWalletKey: userWallet.toBase58(),
          fee: mintProduct.mintFee * rateMul,
        });
        // close modal
        if (res.data.data == undefined) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server mint fail", res.data.data);
          return;
        } else if (res.data.code == 0) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server mint fail", res.data.data);
          return;
        }
        // confirm
        let newTran = res.data.data;
        let resTran = await sendTransaction(
          this.$store.state.walletConnectInfo.connection,
          newTran
        );
        if (resTran.code == 1) {
          let msg = "mint transaction confirmed";
          this.notifyWithSignature(msg, resTran.data);
          console.log("mint signature", resTran.data);
          // save on server
          this.newTransaction({
            user: userWallet.toBase58(),
            mint: mintProduct.mint,
            type: 1, // 1 buy 2 sell
            preAmount: preAmount,
            price: sapPrice2,
            amount: amount,
            signature: resTran.data,
            fee: mintProduct.mintFee,
          });
          await this.getWalletData();
        } else {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("mint error", resTran.data);
        }
      } else if (mintProduct.type == 1) {
        let ltype = parseInt(mintProduct.name.slice(-1));
        let mints = [];
        for (let i = 0; i < SY_LEN; i++) {
          mints.push(new PublicKey(sapPoolMints[i]));
        }
        let trxi = SapInstruction.createSYBuyInstruction(
          sapOwner,
          TOKEN_PROGRAM_ID,
          sapPool, // sap key
          new PublicKey(sapPoolMints[0]),
          new PublicKey(sapPoolMints[1]),
          userSap, // user sap
          userWallet, // user main
          userToken, // user token acc
          SYSVAR_CLOCK_PUBKEY,
          tokenList,
          tokenVualtList,
          oracleList,
          selectedPubkey,
          amountTran,
          ltype,
          mintProduct.mintFee,
          this.syProgramId
        );
        let transaction = new Transaction();
        transaction.add(trxi);
        let { blockhash } =
          await this.$store.state.walletConnectInfo.connection.getRecentBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = userWallet;
        // sign
        let signed = "";
        try {
          signed =
            await this.$store.state.walletConnectInfo.wallet.signTransaction(
              transaction
            );
        } catch (err) {
          this.$notify({
            title: "Error",
            message: "transaction cancelled",
            type: "error",
          });
          console.log("mint sign cancelled", err);
          return;
        }
        const temp = JSON.parse(JSON.stringify(signed));
        temp.signatures = signed.signatures.map((e) => {
          return {
            publicKey: e.publicKey.toBase58(),
            signature: !e.signature ? null : Array.from(e.signature),
          };
        });
        // server mintsy
        let res = await this.$axios.post("/mintsy", {
          data: JSON.stringify(temp),
          amount: amountTran,
          tokenPubkey: selectedPubkey.toBase58(),
          sapKey: sapPool.toBase58(),
          ltype: ltype,
          fee: mintProduct.mintFee,
        });
        if (res.data.data == undefined) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server mint fail", res.data.data);
          return;
        } else if (res.data.code == 0) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server mint fail", res.data.data);
          return;
        }
        // confirm
        let newTran = res.data.data;
        let resTran = await sendTransaction(
          this.$store.state.walletConnectInfo.connection,
          newTran
        );
        if (resTran.code == 1) {
          let msg = "mint transaction confirmed";
          this.notifyWithSignature(msg, resTran.data);
          console.log("mint signature", resTran.data);
          // save on server
          this.newTransaction({
            user: userWallet.toBase58(),
            mint: mintProduct.mint,
            type: 1, // 1 buy 2 sell
            preAmount: preAmount,
            price: sapPrice2,
            amount: amount,
            signature: resTran.data,
            fee: mintProduct.mintFee,
          });
          await this.getWalletData();
        } else {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("mint error", resTran.data);
        }
      }
    },
    async actionBurn() {
      if (!this.actionLoading) {
        this.actionLoading = true;
        try {
          if (!this.determineLock()) {
            await this.goBurn();
          }
        } catch (e) {
          this.$notify({
            title: "Error",
            message: "Burn error.",
            type: "error",
          });
          console.log(e);
        }
        this.actionLoading = false;
      }
    },
    async goBurn() {
      // check token
      if (this.whiteToken.userAcc == "") {
        this.$notify({
          title: "Warning",
          message: "Please choose a valid token/pair account",
          type: "warning",
        });
        return;
      }
      // using data
      let burnProduct = this.thisProduct;
      let sapOwner = new PublicKey(serverOwner);
      let sapPool = new PublicKey(burnProduct.pubkey);
      let sapPoolMint = new PublicKey(burnProduct.mint);
      let sapPoolMints = burnProduct.mints;
      let wallet = this.$store.state.walletConnectInfo.wallet;
      let userWallet = this.$store.state.walletConnectInfo.publicKey;
      let userToken = this.whiteToken.userAcc;
      let tokenList = burnProduct.tokenList.map((e) => {
        return e.pubkey;
      });
      let tokenVualtList = burnProduct.vaultList;
      let oracleList = burnProduct.oracleList;
      let selectedPubkey = new PublicKey(this.whiteToken.pubkey);
      let amount = parseFloat(this.amount);
      let amountTran = amount * 10 ** 9;
      let preAmount = parseFloat(this.sap_amount);
      let sapPrice = parseFloat(this.SAP_price);
      // calculate get price;
      let getValue = parseFloat(this.amount2);
      let sapPrice2 = getValue / amount;
      // check amount
      if (!(amount > 0)) {
        this.$notify({
          title: "Warning",
          message: "Invalid amount",
          type: "warning",
        });
        return;
      }
      // check price
      if (!(sapPrice > 0)) {
        this.$notify({
          title: "Warning",
          message: "Invalid sap price",
          type: "warning",
        });
        return;
      }
      // start
      // find user sap token account
      let sapAcc = null;
      {
        let res = await getTokenAccountMaxAmount(
          this.connection,
          wallet,
          sapPoolMint.toBase58()
        );
        if (res.code == 1) {
          sapAcc = res.data.acc;
        }
      }
      // check if has account
      if (!sapAcc) {
        this.$alert("You do not have a valid sap token account.", "Alert", {
          confirmButtonText: "Ok",
          confirmButtonClass: "alert_btn_ok",
        });
        return;
      }
      this.userSapAcc = sapAcc;
      let userSap = sapAcc;
      // get fee class
      let feeClass = await this.getFeeClass(
        userWallet.toBase58(),
        sapPoolMint.toBase58(),
        sapPool.toBase58()
      );
      let burnFee = Math.round(
        feeClass.burnFee * (1 - feeClass.feeDiscount.burn) * rateMul
      );
      let sapCost = Math.round(feeClass.sapCost * rateMul);
      let performanceFee = Math.round(
        this.performanceFee * (1 - feeClass.feeDiscount.performance) * rateMul
      );
      let maxStaking = feeClass.maxStaking;
      // handle transaction
      if (burnProduct.type == 0) {
        let trxi = SapInstruction.createRedeemInstruction(
          sapOwner,
          TOKEN_PROGRAM_ID,
          sapPool, // sap key
          sapPoolMint, // sap mint
          userSap, // user sap
          userWallet, // user main
          userToken, // user token acc
          new PublicKey(serverManager),
          tokenList,
          tokenVualtList,
          oracleList,
          selectedPubkey,
          amountTran,
          burnFee,
          sapCost,
          performanceFee,
          this.sapProgramId
        );
        let transaction = new Transaction();
        transaction.add(trxi);
        let { blockhash } = await this.connection.getRecentBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = userWallet;
        // sign
        let signed = "";
        try {
          signed =
            await this.$store.state.walletConnectInfo.wallet.signTransaction(
              transaction
            );
        } catch (err) {
          this.$notify({
            title: "Error",
            message: "transaction cancelled",
            type: "error",
          });
          console.error("burn sign cancelled", err);
          return;
        }
        const temp = JSON.parse(JSON.stringify(signed));
        temp.signatures = signed.signatures.map((e) => {
          return {
            publicKey: e.publicKey.toBase58(),
            signature: !e.signature ? null : Array.from(e.signature),
          };
        });
        // server redeem
        let res = await this.$axios.post("/redeem", {
          data: JSON.stringify(temp),
          amount: amountTran,
          tokenPubkey: selectedPubkey.toBase58(),
          sapKey: sapPool.toBase58(),
          feeClass,
          maxStaking,
        });
        if (!res.data.code) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server redeem fail", res.data.data);
          return;
        } else if (res.data.code == 0) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server redeem fail", res.data.data);
          return;
        }
        // confirm
        let newTran = res.data.data;
        let resTran = await sendTransaction(
          this.$store.state.walletConnectInfo.connection,
          newTran
        );
        if (resTran.code == 1) {
          let msg = "burn transaction confirmed";
          this.notifyWithSignature(msg, resTran.data);
          console.log("burn signature", resTran.data);
          // save data on server
          this.newTransaction({
            user: userWallet.toBase58(),
            mint: burnProduct.mint,
            type: 2, // 1 buy 2 sell
            preAmount: preAmount,
            price: sapPrice2,
            amount: amount,
            signature: resTran.data,
            fee: burnFee / rateMul,
            performanceFee: performanceFee / rateMul,
          });
          await this.getWalletData();
        } else {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("burn error", resTran.data);
        }
      } else if (burnProduct.type == 1) {
        let ltype = parseInt(burnProduct.name.slice(-1));
        let mints = [];
        for (let i = 0; i < SY_LEN; i++) {
          mints.push(new PublicKey(sapPoolMints[i]));
        }
        let trxi = SapInstruction.createSYRedeemInstruction(
          TOKEN_PROGRAM_ID,
          sapPool, // sap key
          new PublicKey(sapPoolMints[0]),
          new PublicKey(sapPoolMints[1]),
          userSap, // user sap
          userWallet, // user main
          userToken, // user token acc
          new PublicKey(serverManager),
          SYSVAR_CLOCK_PUBKEY,
          tokenList,
          tokenVualtList,
          oracleList,
          amountTran,
          ltype,
          burnFee,
          sapCost,
          performanceFee,
          this.syProgramId
        );
        let transaction = new Transaction();
        transaction.add(trxi);
        let { blockhash } =
          await this.$store.state.walletConnectInfo.connection.getRecentBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = userWallet;
        // sign
        let signed = "";
        try {
          signed =
            await this.$store.state.walletConnectInfo.wallet.signTransaction(
              transaction
            );
        } catch (err) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("burn sign cancelled", err);
          return;
        }
        const temp = JSON.parse(JSON.stringify(signed));
        temp.signatures = signed.signatures.map((e) => {
          return {
            publicKey: e.publicKey.toBase58(),
            signature: !e.signature ? null : Array.from(e.signature),
          };
        });
        // server redeemsy
        let res = await this.$axios.post("/redeemsy", {
          data: JSON.stringify(temp),
          amount: amountTran,
          tokenPubkey: selectedPubkey.toBase58(),
          sapKey: sapPool.toBase58(),
          ltype: ltype,
          maxStaking,
        });
        if (res.data.data == undefined) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server redeem fail", res.data.data);
          return;
        } else if (res.data.code == 0) {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("server redeem fail", res.data.data);
          return;
        }
        // confirm
        let newTran = res.data.data;
        let resTran = await sendTransaction(
          this.$store.state.walletConnectInfo.connection,
          newTran
        );
        if (resTran.code == 1) {
          let msg = "burn transaction confirmed";
          this.notifyWithSignature(msg, resTran.data);
          console.log("burn signature", resTran.data);
          // save on server
          this.newTransaction({
            user: userWallet.toBase58(),
            mint: burnProduct.mint,
            type: 2, // 1 buy 2 sell
            preAmount: preAmount,
            price: sapPrice2,
            amount: amount,
            signature: resTran.data,
            fee: burnFee / rateMul,
            performanceFee: performanceFee / rateMul,
          });
          await this.getWalletData();
        } else {
          this.$notify({
            title: "Failure",
            message: "transaction failed",
            type: "error",
          });
          console.log("burn error", resTran.data);
        }
      }
    },
    async actionPreMint() {
      if (!this.actionLoading) {
        this.actionLoading = true;
        try {
          // check token
          if (this.whiteToken.userAcc == "") {
            this.$notify({
              title: "Warning",
              message: "Please choose a valid token/pair account",
              type: "warning",
            });
            this.actionLoading = false;
            return;
          }
          // using data
          let amount = parseFloat(this.amount) * 10 ** 9;
          let product = this.thisProduct;
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let userWallet = this.$store.state.walletConnectInfo.publicKey;
          let fee = Math.round(product.mintFee * rateMul);
          let preAmount = parseFloat(this.sap_amount);
          // check
          if (!(amount > 0)) {
            this.$notify({
              title: "Warning",
              message: "Invalid amount",
              type: "warning",
            });
            this.actionLoading = false;
            return;
          }
          // check limit
          if (product.type == 1) {
            let res = await checkSyLimit(
              this.connection,
              product.preMint,
              product.limit - parseFloat(this.amount)
            );
            if (!res) {
              this.$notify({
                title: "Warning",
                message: "This pool is limited.",
                type: "warning",
              });
              this.actionLoading = false;
              return;
            }
          }
          let res = await preMintSy(
            this.connection,
            wallet,
            product.pubkey,
            product.preMint,
            amount,
            fee
          );
          if (res.code != 1) {
            console.log("pre mint fail ", res);
            this.$notify({
              title: "Error",
              message: "Pre mint fail.",
              type: "error",
            });
            this.actionLoading = false;
            return;
          }
          let res1 = await this.$axios.post("/premintsy", res);
          if (res1.data.data == undefined) {
            this.$notify({
              title: "Failure",
              message: "transaction failed",
              type: "error",
            });
            console.log("server pre mint fail", res1);
            this.actionLoading = false;
            return;
          } else if (res1.data.code == 0) {
            this.$notify({
              title: "Failure",
              message: "transaction failed",
              type: "error",
            });
            console.log("server pre mint fail", res1);
            this.actionLoading = false;
            return;
          }
          // confirm
          let newTran = res1.data.data;
          let resTran = await sendTransaction(
            this.$store.state.walletConnectInfo.connection,
            newTran
          );
          if (resTran.code == 1) {
            let msg = "pre mint transaction confirmed";
            this.notifyWithSignature(msg, resTran.data);
            console.log("pre mint signature", resTran.data);
            // save on server
            this.newTransaction({
              user: userWallet.toBase58(),
              mint: product.preMint,
              type: 1, // 1 buy 2 sell
              preAmount: preAmount,
              price: product.init_price,
              amount: amount,
              signature: resTran.data,
              fee: fee,
            });
            await this.getWalletData();
          }
        } catch (e) {
          this.$notify({
            title: "Error",
            message: "Pre mint error.",
            type: "error",
          });
          console.log(e);
        }
        this.actionLoading = false;
      }
    },
    async actionPreBurn() {
      if (!this.actionLoading) {
        this.actionLoading = true;
        try {
          // check token
          if (this.whiteToken.userAcc == "") {
            this.$notify({
              title: "Warning",
              message: "Please choose a valid token/pair account",
              type: "warning",
            });
            this.actionLoading = false;
            return;
          }
          // using data
          let amount = parseFloat(this.amount) * 10 ** 9;
          let product = this.thisProduct;
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let userWallet = this.$store.state.walletConnectInfo.publicKey;
          // get fee class
          let feeClass = await this.getFeeClass(
            userWallet.toBase58(),
            product.preMint,
            product.pubkey
          );
          if (!feeClass) {
            console.error("null fee class");
            return;
          }
          let fee = Math.round(
            feeClass.burnFee * (1 - feeClass.feeDiscount.burn) * rateMul
          );
          let maxStaking = feeClass.maxStaking;
          let preAmount = parseFloat(this.sap_amount);
          // check
          if (!(amount > 0)) {
            this.$notify({
              title: "Warning",
              message: "Invalid amount",
              type: "warning",
            });
            this.actionLoading = false;
            return;
          }
          let res = await preBurnSy(
            this.connection,
            wallet,
            product.pubkey,
            product.preMint,
            amount,
            fee
          );
          if (res.code != 1) {
            console.log("pre burn fail ", res);
            this.$notify({
              title: "Error",
              message: "Pre burn fail.",
              type: "error",
            });
            this.actionLoading = false;
            return;
          }
          let res1 = await this.$axios.post("/preburnsy", res);
          if (res1.data.data == undefined) {
            this.$notify({
              title: "Failure",
              message: "transaction failed",
              type: "error",
            });
            console.log("server pre burn fail", res1);
            this.actionLoading = false;
            return;
          } else if (res1.data.code == 0) {
            this.$notify({
              title: "Failure",
              message: "transaction failed",
              type: "error",
            });
            console.log("server pre burn fail", res1);
            this.actionLoading = false;
            return;
          }
          // confirm
          let newTran = res1.data.data;
          let resTran = await sendTransaction(
            this.$store.state.walletConnectInfo.connection,
            newTran
          );
          if (resTran.code == 1) {
            let msg = "pre burn transaction confirmed";
            this.notifyWithSignature(msg, resTran.data);
            console.log("pre burn signature", resTran.data);
            // save on server
            this.newTransaction({
              user: userWallet.toBase58(),
              mint: product.preMint,
              type: 2, // 1 buy 2 sell
              preAmount: preAmount,
              price: product.init_price,
              amount: amount,
              signature: resTran.data,
              fee: fee,
              maxStaking,
            });
            await this.getWalletData();
          }
        } catch (e) {
          this.$notify({
            title: "Error",
            message: "Pre burn error.",
            type: "error",
          });
          console.log(e);
        }
        this.actionLoading = false;
      }
    },
    async actionPreClaim() {
      if (!this.actionLoading) {
        this.actionLoading = true;
        try {
          // using data
          let product = this.thisProduct;
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let userWallet = this.$store.state.walletConnectInfo.publicKey;
          let preAmount = parseFloat(this.sap_amount);
          // check
          let res = await preClaimSy(
            this.connection,
            wallet,
            product.pubkey,
            product.preMint,
            product.mint
          );
          if (res.code == -3) {
            this.$notify({
              title: "Warning",
              message: "Pre sap amount is zero.",
              type: "warning",
            });
            this.actionLoading = false;
            return;
          } else if (res.code != 1) {
            console.log("pre claim fail ", res);
            this.$notify({
              title: "Error",
              message: "Pre claim fail.",
              type: "error",
            });
            this.actionLoading = false;
            return;
          }
          let amount = res.preSapAmount;
          let res1 = await this.$axios.post("/preclaimsy", res);
          if (res1.data.data == undefined) {
            this.$notify({
              title: "Failure",
              message: "transaction failed",
              type: "error",
            });
            console.log("server pre claim fail", res1);
            this.actionLoading = false;
            return;
          } else if (res1.data.code == 0) {
            this.$notify({
              title: "Failure",
              message: "transaction failed",
              type: "error",
            });
            console.log("server pre claim fail", res1);
            this.actionLoading = false;
            return;
          }
          // confirm
          let newTran = res1.data.data;
          let resTran = await sendTransaction(
            this.$store.state.walletConnectInfo.connection,
            newTran
          );
          if (resTran.code == 1) {
            let msg = "pre claim transaction confirmed";
            this.notifyWithSignature(msg, resTran.data);
            console.log("pre claim signature", resTran.data);
            // save on server
            this.newTransaction({
              user: userWallet.toBase58(),
              mint: product.mint,
              preMint: product.preMint,
              type: 1, // 1 buy 2 sell 3 pre claim
              preAmount: preAmount,
              price: product.init_price,
              amount: amount,
              signature: resTran.data,
              fee: 0,
            });
            await this.getWalletData();
          }
        } catch (e) {
          this.$notify({
            title: "Error",
            message: "Pre mint error.",
            type: "error",
          });
          console.log(e);
        }
        this.actionLoading = false;
      }
    },
    async getFeeClass(user, mint, pubkey) {
      if (!user || !mint || !pubkey) {
        return;
      }
      // get burn fee
      let wallet = this.$store.state.walletConnectInfo.wallet;
      let userNpKey = "";
      {
        let res = await getTokenAccountMaxAmount(
          this.connection,
          wallet,
          npMintKey
        );
        if (res.code == 1) {
          userNpKey = res.data.acc.toBase58();
        }
      }
      let res = await this.$axios.post("/getFeeClass", {
        user,
        mint,
        pubkey,
        maxStaking: userNpKey,
      });
      let feeClass;
      if (res.data.code) {
        feeClass = res.data.data;
      } else {
        console.error("get fee class error", res.data);
      }
      feeClass.maxStaking = userNpKey;
      return feeClass;
    },
    async getSAPBalance(pubkey, mint) {
      this.sap_amount = 0;
      let sapAmountTemp = 0;
      {
        let res = await getTokenAccountMaxAmount(
          this.connection,
          { publicKey: pubkey },
          mint
        );
        if (res.code == 1) {
          sapAmountTemp = res.data.amount;
        }
      }
      this.sap_amount = sapAmountTemp;
    },
    async getOraclePrices() {
      let conn = new Connection(rpcUrl, rpcConfig);
      if (!this.connection) {
        this.connection = this.$store.state.walletConnectInfo.connection;
      }
      for (let i = 0; i < this.tokens.length; i++) {
        if (conn._rpcEndpoint.replace(" ", "") == rpcUrl) {
          if (this.tokens[i].token == "SYP") {
            if (this.tokenPrices["SYP"] === undefined) {
              this.tokenPrices["SYP"] = { price: 0.05, decimal: 9 };
            }
          } else {
            let [thisTokenPrice, thisTokenExpo] = await getTokenPrice(
              rpcUrl,
              new PublicKey(this.tokens[i].oracle)
            );
            this.tokens[i].price = thisTokenPrice;
            // this.tokens[i].decimal = Math.abs(thisTokenExpo);
            if (this.tokenPrices[this.tokens[i].name] === undefined) {
              this.tokenPrices[this.tokens[i].name] = {
                price: thisTokenPrice,
                decimal: Math.abs(thisTokenExpo),
              };
            }
          }
        }
      }
      console.log("done oracle");
    },
    async getSAPValue(product) {
      let pubkeyList = this.funds.map((e) => {
        return e.pubkey;
      });
      let index = pubkeyList.indexOf(product.pubkey);
      let oneFund = this.funds[index];
      if (oneFund.enableWoo) {
        let res = await this.$axios.get("/getFundWooAsset", {
          params: {
            publicKey: oneFund.pubkey,
          },
        });
        if (res.data.code == 1) {
          oneFund.wooAsset = res.data.data;
        } else {
          console.warn(res);
        }
      }
      let [maxA, percent, apy, cap, supply, onePrice] = await getAssetValue(
        this.$store.state.walletConnectInfo.connection,
        oneFund
      );
      return onePrice;
    },
    async getSYValue(product) {
      let pubkeyList = this.funds.map((e) => {
        return e.pubkey;
      });
      let index = pubkeyList.indexOf(product.pubkey);
      let oneFund = this.funds[index];
      if (oneFund.enableWoo) {
        let res = await this.$axios.get("/getFundWooAsset", {
          params: {
            publicKey: oneFund.pubkey,
          },
        });
        if (res.data.code == 1) {
          oneFund.wooAsset = res.data.data;
        } else {
          console.warn(res);
        }
      }
      let results = await getAssetValueSY(
        this.$store.state.walletConnectInfo.connection,
        rpcUrl,
        oneFund
      );
      console.log("get asset value sy", results);
      let prices = [];
      for (let i = 0; i < results.length; i++) {
        // let assetValue1 = [0 maxA, 1 percent1, 2 apy1, 3 apy30d1, 4 apy1d1, 5 cap1, 6 r1s, 7 price[0], 8 assets]
        prices.push(parseFloat(results[i][7]));
      }
      return prices;
    },
    async refresh() {
      this.refreshing = true;
      try {
        await this.getWalletData();
        if (this.initCuurrency) {
          this.SAP_price = 0;
          try {
            if (this.thisProduct.type == 1 && this.thisProduct.status == 1) {
              this.SAP_price = this.thisProduct.init_price;
            } else {
              if (this.activeName == "MINT") {
                this.tokenPrices = [];
                if (this.thisProduct.type == 0) {
                  let tempPrice = await this.getSAPValue(this.thisProduct);
                  this.SAP_price = tempPrice;
                } else if (this.thisProduct.type == 1) {
                  this.SYPrices = await this.getSYValue(this.thisProduct);
                  let ltype = this.thisProduct.ltype;
                  let tempPrice = parseFloat(this.SYPrices[ltype - 1]);
                  this.SAP_price = tempPrice;
                }
              } else if (this.activeName == "BURN") {
                if (this.thisProduct.type == 0) {
                  let tempPrice = await this.getSAPValue(this.thisProduct);
                  this.SAP_price = tempPrice;
                } else if (this.thisProduct.type == 1) {
                  this.SYPrices = await this.getSYValue(this.thisProduct);
                  let ltype = this.thisProduct.name.slice(-1);
                  let tempPrice = parseFloat(this.SYPrices[ltype - 1]);
                  this.SAP_price = tempPrice;
                }
              }
            }
          } catch (e) {
            console.log("refreshing error", e);
          }
        } else {
          await this.currencyChanged();
        }
      } catch (err) {
        console.error("refresh error", err);
      }
      this.refreshing = false;
    },
    async getSYMember(sapPoolKey, userSapKey, ltype) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      // get member key
      let memberKey = await getSYMemberKey(wallet);
      // get member data
      let memberData = await getSYMemberData(this.connection, memberKey);
      if (!memberData) {
        // create member account
        let res = await createSYMemberAcc(this.connection, wallet);
        if (res.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member account sign cancelled",
            type: "warning",
          });
          return;
        } else if (res.code == -2) {
          this.$notify({
            title: "Error",
            message: "Wait member account time out",
            type: "error",
          });
          return;
        } else if (res.code == -3) {
          console.log(res);
          this.$notify({
            title: "Error",
            message: "Create member account error",
            type: "error",
          });
          return;
        } else if (res.code == 1) {
          memberData = res.data;
        }
      }
      if (memberData.userWallet == nullKey) {
        // create member
        let res3 = await createSYMember(
          this.connection,
          wallet,
          sapPoolKey,
          memberKey,
          userSapKey,
          0,
          ltype
        );
        if (res3.code == -2) {
          this.$notify({
            title: "Error",
            message: "Wait member account time out",
            type: "error",
          });
          return;
        } else if (res3.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member sign cancelled",
            type: "warning",
          });
          return;
        } else if (res3.code == -3) {
          console.log("create sy member error", res3.data);
          this.$notify({
            title: "Error",
            message: "Create member error",
            type: "error",
          });
          return;
        } else if (res3.code == 1) {
          memberData = res3.data;
        }
      }
      return { memberKey, memberData };
    },
    calculateMax1() {
      // to avoid mint fail, could not mint max amount
      if (this.activeName == "MINT") {
        let fee = this.thisProduct.mintFee;
        this.amount = Math.round(
          (this.whiteToken.amount * this.whiteToken.price * 0.98) /
            (this.SAP_price * (1 + fee))
        );
      } else if (this.activeName == "BURN") {
        this.amount = this.sap_amount;
      }
      if (this.amount == "NaN" || this.amount == NaN) {
        this.amount = 0;
      }
    },
    calculateMax2() {
      if (this.activeName == "MINT") {
        // this.amount2 = Math.round(this.whiteToken.amount * this.whiteToken.price * 0.98);
      }
      if (this.amount2 == "NaN" || this.amount2 == NaN) {
        this.amount2 = 0;
      }
    },
    optionChanged() {
      this.whiteToken = this.whiteList[this.whiteListSelect];
      this.getWalletData();
    },
    async currencyChanged() {
      this.refreshing = true;
      if (this.$store.state.walletConnectInfo) {
        this.initCuurrency = true;
        this.SAP_price = 0;
        let wallet = this.$store.state.walletConnectInfo.wallet;
        this.connection = this.$store.state.walletConnectInfo.connection;
        this.thisProduct = this.productList[this.productSelect];
        let product = this.thisProduct;
        this.feeClass = await this.getFeeClass(
          wallet.publicKey.toBase58(),
          product.mint,
          product.pubkey
        );
        if (!this.feeClass) {
          console.warn("null fee class");
          return;
        }
        this.amount2 = 0;
        try {
          let whiteListTemp = this.thisProduct.whiteList;
          if (product.type == 1 && product.status == 1) {
            whiteListTemp = whiteListTemp.filter((e) => {
              return e.name == "USDC";
            });
          }
          let whiteList = whiteListTemp.filter((e) => {
            return e.name != "";
          });
          for (let i = 0; i < whiteList.length; i++) {
            let e = whiteList[i];
            e.label = e.name;
            e.token = e.name;
            e.oracle = getOraclePriceKey(e.name);
            e.pubkey = new PublicKey(e.pubkey);
            {
              let res = await getTokenPrice2(this.connection, e.name);
              e.price = res.data;
            }
            {
              let res = await getMintData(this.connection, e.pubkey);
              if (res.code == 1) {
                e.decimals = res.data.decimals;
              } else {
                console.error("get", e.name, "mint data error");
                e.decimals = 9;
              }
            }
            {
              let res = await getTokenAccountMaxAmount(
                this.connection,
                wallet,
                e.pubkey
              );
              if (res.code == 1) {
                e.amount = res.data.amount;
                e.userAcc = res.data.acc;
              }
            }
          }
          this.whiteList = whiteList;
          this.whiteListSelect = 0;
          this.whiteToken = this.whiteList[0];
          this.mintFee = this.thisProduct.mintFee;
          this.burnFeeTable = this.thisProduct.burnFee;
          this.performanceFee = this.thisProduct.performanceFee;
          // await this.getSAPBalance(this.$store.state.walletConnectInfo.publicKey, this.thisProduct.mint)
          this.refresh();
        } catch (e) {
          console.log("currency change error", e);
        }
      }
    },
    async newTransaction(trans) {
      let res = { code: 0 };
      let index = 0;
      while (res.code == 0) {
        // save on server
        index++;
        console.log("new transaction try ", index);
        res = await this.$axios.post("/newTransaction", trans);
      }
      console.log("new transaction ok ");
    },
    closeDialog() {
      // store.commit('SET_DIALOG2_CLOSE');
      this.dialogVisible = false;
    },
    initDialog() {
      this.amount = 0;
      this.amount2 = 0;
    },
    notifyWithSignature(msg, signature) {
      const h = this.$createElement;
      this.$notify({
        title: "Success",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("p", {}, msg),
          h("p", {}, [
            h(
              "a",
              {
                attrs: {
                  href: "https://explorer.solana.com/tx/" + signature,
                  target: "_blank",
                },
              },
              "view on explore"
            ),
            // h('a', { attrs: { href: 'https://explorer.solana.com/tx/' + signature + '?cluster=devnet', target: '_blank' } }, 'view on explore')
          ]),
        ]),
        type: "success",
      });
    },
    sendsmall(done) {
      if (this.activeName == "MINT") this.small("mintbiger");
      else this.small("burnbiger");
      done();
    },
  },
  computed: {
    curFund() {
      return store.state.dialogCurFund;
    },
  },
  watch: {
    dialogVisible: function (newValue, oldValue) {
      if (newValue == true) {
        this.initDialog();
        this.currencyChanged();
      }
    },
    amount: function (newValue, oldValue) {
      if (this.amountElement == 1) {
        let temp = parseFloat(newValue);
        if (temp >= 0) {
          let sapPrice = this.SAP_price;
          if (sapPrice == 0) {
            sapPrice = 1;
          }
          let value = temp * sapPrice;
          let feeValue = 0;
          if (this.activeName == "MINT") {
            let mintFeeValue = value * this.thisProduct.mintFee;
            feeValue = -1 * mintFeeValue;
          } else if (this.activeName == "BURN") {
            let feeClass = this.feeClass;
            // calculate burn fee
            let burnFeeValue =
              value * feeClass.burnFee * (1 - feeClass.feeDiscount.burn);
            // calculate performance fee
            let performanceFeeValue = 0;
            if (sapPrice > feeClass.sapCost) {
              let costValue = temp * feeClass.sapCost;
              performanceFeeValue =
                (value - costValue) *
                feeClass.performanceFee *
                (1 - feeClass.feeDiscount.performance);
            }
            feeValue = burnFeeValue + performanceFeeValue;
          }
          let amount2 = value - feeValue;
          if (Math.abs(this.amount2 - amount2) > 0.001) {
            this.amount2 = amount2;
          }
        }
      }
    },
    amount2: function (newValue, oldValue) {
      if (this.amountElement == 2) {
        let temp2 = parseFloat(newValue);
        if (temp2 >= 0) {
          let sapPrice = this.SAP_price;
          if (sapPrice == 0) {
            sapPrice = 1;
          }
          let value = temp2;
          let feeValue = 0;
          if (this.activeName == "MINT") {
            let mintFeeValue = value / (1 - this.thisProduct.mintFee) - value;
            feeValue = -1 * mintFeeValue;
          } else if (this.activeName == "BURN") {
            let feeClass = this.feeClass;
            // calculate burn fee
            let burnRate = feeClass.burnFee * (1 - feeClass.feeDiscount.burn);
            // calculate performance fee
            let performanceRate = 0;
            if (sapPrice > feeClass.sapCost) {
              performanceRate =
                feeClass.performanceFee *
                (1 - feeClass.feeDiscount.performance);
            }
            // calculate amount
            let feeRate =
              (1 - burnRate - performanceRate) * sapPrice +
              performanceRate * feeClass.sapCost;
            let amount = value / feeRate;
            let value2 = amount * sapPrice;
            feeValue = value2 - value;
          }
          let amount = (value + feeValue) / sapPrice;
          if (Math.abs(this.amount - amount) > 0.001) {
            this.amount = amount;
          }
        }
      }
    },
    SAP_price: function (newValue, oldValue) {
      if (newValue != oldValue) {
        let temp = parseFloat(this.amount);
        if (temp >= 0) {
          let sapPrice = newValue;
          if (sapPrice == 0) {
            sapPrice = 1;
          }
          let value = temp * sapPrice;
          let feeValue = 0;
          if (this.activeName == "MINT") {
            let mintFeeValue = value * this.thisProduct.mintFee;
            feeValue = mintFeeValue;
          } else if (this.activeName == "BURN") {
            let feeClass = this.feeClass;
            // calculate burn fee
            let burnFeeValue =
              value * feeClass.burnFee * (1 - feeClass.feeDiscount.burn);
            // calculate performance fee
            let performanceFeeValue = 0;
            if (sapPrice > feeClass.sapCost) {
              let costValue = temp * feeClass.sapCost;
              performanceFeeValue =
                (value - costValue) *
                feeClass.performanceFee *
                (1 - feeClass.feeDiscount.performance);
            }
            feeValue = (burnFeeValue + performanceFeeValue) * -1;
          }
          this.amount2 = value + feeValue;
        }
      }
    },
  },
};
</script>

<style lang="less">
.mb {
  display: none;
}

.title {
  font-size: 20px;
  color: white;
  text-transform: uppercase;
}

.title2 {
  font-size: 18px;
  color: white;
}

.asset {
  font-size: 10px;
  color: white;
  background-color: #1b1c38;
  display: inline-block;
  padding: 3px 7px;
  border-radius: 3px;
}

.selector {
  margin-top: 15px;
  width: 100%;

  .el-input__inner {
    font-size: 14px;
    color: white;
    line-height: 40px;
    background-color: #1b1c3a;
  }
}

.price > *:nth-child(2) {
  text-align: right;
}

.amount {
  margin-top: 15px;
  border-bottom: solid #1b1b1b 2px;
  padding-bottom: 5px;

  .title {
    font-weight: normal;
    text-transform: none;
  }

  .input {
    margin-top: 15px;

    .el-input__inner {
      font-size: 14px;
      color: white;
      line-height: 40px;
      background-color: #1b1c3a;
    }

    .max {
      background-color: transparent;
      font-size: 12px;
      padding: 0 20px;
      line-height: 40px;
      border: none;
      color: gray;
    }
  }
}

.unitmt20 {
  margin-top: 20.5px !important;
}

.tradetittle {
  color: transparent;
}

.fee {
  margin-top: 15px;

  .title {
    font-weight: normal;
    text-transform: none;
  }

  .unit {
    margin-top: 18px;
    padding: 15px;
    background-color: #1b1c3a;
    border-radius: 10px;
    height: 100%;

    p {
      color: white;
      line-height: 50px;
      font-size: 20px;
    }

    .pbb {
      border-bottom: 2px solid #221636;
    }

    .el-table {
      background-color: transparent;

      td,
      th {
        padding: 0;
        border-bottom: 2px solid #221636 !important;
      }

      tbody {
        tr:last-child {
          td {
            border: none !important;
          }
        }
      }

      td,
      th.is-leaf {
        border: none;
      }

      th,
      tr {
        background-color: transparent;
      }

      .el-table__body tr:hover > td {
        background-color: transparent;
      }

      .cell {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.51;
        color: white;
      }
    }
  }
}

.balance {
  margin-top: 15px;
  color: white;

  & > span {
    font-weight: normal;
  }
}

.btn-box {
  margin-top: 15px;

  .text {
    font-size: 14px;
    color: white;
    white-space: nowrap;

    & > span {
      font-weight: normal;
    }
  }

  .btn {
    font-size: 14px;
    background-color: #1b1c3a;
    padding: 12px 5px;
    min-width: 100%;
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-image: linear-gradient(to right, #d922fa, #1be9a8) 1;
    color: white;
    text-align: center;
    text-transform: none !important;
    transition: all 0.4s;

    &.disable {
      filter: grayscale(0) contrast(0.3);
      cursor: not-allowed;
    }
  }

  .refresh {
    height: 35px;
    width: 100%;
    position: relative;
    background-color: transparent;
    background-position: center;
    background-image: url("../assets/img/refresh.png");
    background-size: contain;
    background-repeat: no-repeat;
    border: none;

    &.loading {
      animation: 3s linear 0;
      animation-name: refreshing;
      animation-iteration-count: infinite;

      @keyframes refreshing {
        0% {
          transform: rotate(0turn);
        }

        50% {
          transform: rotate(0.5turn);
        }

        100% {
          transform: rotate(1turn);
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .pc {
    display: none;
  }

  .mb {
    display: block;
  }

  .ntitle {
    opacity: 0;
    line-height: 22px;
  }

  .el-dialog {
    width: 335px !important;
    background: #131226;
    border-radius: 10px;
    border-width: 4px;

    .el-dialog__header {
      padding: 15px 15px 5px 15px;
    }

    .el-dialog__body {
      padding: 5px 15px;
    }

    .amount {
      margin-top: 5px;

      .title {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .title {
    font-size: 16px;
    line-height: 22px;
  }

  .title2 {
    font-size: 12px;
  }

  .fee {
    .title {
      font-size: 14px;
      font-weight: normal;
    }

    .unit {
      padding: 5px;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 10px 5px;

      p {
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
      }

      .el-table {
        .cell {
          font-size: 12px;
          line-height: 17px;
          padding: 3px;
        }
      }
    }
  }

  .btn-box {
    .btn {
      height: 40px;
      border-width: 1px;
      font-weight: normal;
      font-size: 16px;
    }
  }
}
</style>

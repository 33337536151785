import { Provider } from "@project-serum/anchor";
import { Swap } from "@project-serum/swap";
import { TokenListContainer } from "@solana/spl-token-registry";
import { PublicKey } from "@solana/web3.js";
import { loadMarket, findMarketByMints } from "./market";
import { getTokenPrice2 } from "../oracleList";
import {
  createTokenAccount,
  getTokenAccountMaxAmount,
  getTokenAccounts,
} from "../getBalance";
import { signAndSendTransaction } from "../sendTransaction";
import BN from "bn.js";
const usdcMintKey = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
const usdtMintKey = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
const tokenInfoList = require("../tokenList.json");
const tokenInfoList1 = tokenInfoList.filter((e) => {
  if (e.chainId === 101) {
    if (e.address == usdcMintKey || e.address == usdtMintKey) return true;
    if (e.extensions) {
      if (e.extensions.serumV3Usdc) return true;
    }
  }
  return false;
});
const MarketList = require("./market.json");

// connection
// local
// export const programId = "6NVQX4DKwF4k94Th4AiXy3htx4gbMzZAenGaH9y8Z6zz";
// devnet
// export const programId = "DESVgJVGajEgKGXhb6XmqDHGz3VjdgP7rEVESBgxmroY";
// mainnet
export const programId = "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin";
const programAcc = new PublicKey(programId);

export function getTokenInfoList() {
  return new TokenListContainer(tokenInfoList1);
}

export function getFixedMarket(mintAKey, mintBKey) {
  return MarketList.find((e) => {
    return (
      (mintAKey === e.mintA && mintBKey === e.mintB) ||
      (mintAKey === e.mintB && mintBKey === e.mintA)
    );
  });
}

export function getToken2(tokenInfoList, tokenName) {
  let list = tokenInfoList.getList();
  let token = list.find((e) => {
    return e.symbol === tokenName;
  });
  if (token) {
    return token;
  } else {
    return { address: null };
    // console.error('can not find token', tokenName);
    // throw new Error('can not find token', tokenName);
  }
}

export async function getSwap(connection, wallet) {
  let userProvider = new Provider(connection, wallet, "confirmed");
  let tokenInfoList = getTokenInfoList();
  let swap = new Swap(userProvider, tokenInfoList);
  return swap;
}

export async function loadSwapMarket(connection, swap, mintAName, mintBName) {
  // use mint
  let tokenList = getTokenInfoList();
  let mintAKey = getToken2(tokenList, mintAName).address;
  let mintBKey = getToken2(tokenList, mintBName).address;
  // get market key
  let route = getFixedMarket(mintAKey, mintBKey);
  if (!route) {
    route = swap
      .route(new PublicKey(mintAKey), new PublicKey(mintBKey))
      .map((e) => {
        return e.toBase58();
      });
  } else {
    route = [route.marketAddress];
  }
  if (route) {
    // get all market
    let marketList = [];
    let priceList = [];
    for (let i = 0; i < route.length; i++) {
      // load market
      let marketKey = route[i];
      let res = await loadMarket(connection, marketKey);
      if (res.code == 1) {
        let baseMintKey = res.data.market.baseMintAddress.toBase58();
        let quoteMintKey = res.data.market.quoteMintAddress.toBase58();
        console.log(mintAKey, mintBKey);
        console.log(baseMintKey, quoteMintKey);
        if (mintAKey == baseMintKey && mintBKey == quoteMintKey) {
          res.data.price = res.data.bidPrice[0];
          priceList.push(res.data.bidPrice[0]);
        } else if (mintAKey == quoteMintKey && mintBKey == baseMintKey) {
          res.data.price = 1 / res.data.askPrice[0];
          priceList.push(1 / res.data.askPrice[0]);
        } else {
          priceList.push(NaN);
        }
        marketList.push(res.data);
      }
    }
    console.log("market key list", route);
    console.log("market list", marketList);
    console.log("price list", priceList);
    let minPrice = Math.min(...priceList);
    let minIndex = priceList.indexOf(minPrice);
    if (minIndex == -1) {
      return { code: 1, msg: "find market ok", data: marketList[0] };
    } else {
      return { code: 1, msg: "find market ok", data: marketList[minIndex] };
    }
  } else {
    return { code: -11, msg: "there is no market" };
  }
}

export async function swap(connection, wallet, swap, market, params) {
  // use mint
  let tokenList = await getTokenInfoList();
  let mintAKey = getToken2(tokenList, params.mintAName).address;
  let mintBKey = getToken2(tokenList, params.mintBName).address;
  // use amount and rate
  // let amount = params.amount / priceA * priceB;
  let amount = params.amount;
  let tolerance = 0;
  if (params.tolerance > 0) {
    tolerance = params.tolerance / 100;
  }
  let rate = market.price * (1 - tolerance);
  // use account
  let walletAcc = wallet.publicKey;
  let userMintAAcc;
  let userMintBAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, mintAKey);
    if (res.code == 1) {
      userMintAAcc = res.data.acc;
    } else {
      return { code: 0, msg: "user has no mint a account", data: null };
    }
  }
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, mintBKey);
    if (res.code == 1) {
      userMintBAcc = res.data.acc;
    } else {
      let res = await createTokenAccount(connection, wallet, mintBKey);
      if (res.code == 1) {
        userMintBAcc = await getTokenAccounts(
          connection,
          walletAcc,
          new PublicKey(mintBKey)
        );
      } else {
        return res;
      }
    }
  }
  // use order
  let openOrders = await market.findOpenOrdersAccountsForOwner(
    connection,
    walletAcc,
    0
  );
  // use decimals
  let marketMintA = market.baseMintAddress.toBase58();
  let marketMintB = market.quoteMintAddress.toBase58();
  let fromDecimals = 0;
  let quoteDecimals = 0;
  if (mintAKey == marketMintA && mintBKey == marketMintB) {
    fromDecimals = market._baseSplTokenDecimals;
    quoteDecimals = market._quoteSplTokenDecimals;
  } else if (mintAKey == marketMintB && mintBKey == marketMintA) {
    fromDecimals = market._quoteSplTokenDecimals;
    quoteDecimals = market._baseSplTokenDecimals;
  } else {
    return { code: -3, msg: "mint not match market" };
  }
  // use data
  let amountBuffer = new BN(amount * 10 ** fromDecimals);
  let rateBuffer = {
    rate: new BN(Math.round(rate * 10 ** quoteDecimals)),
    fromDecimals,
    quoteDecimals,
    strict: false,
  };
  // use transaction
  let txs = await swap.swapTxs({
    fromMint: new PublicKey(mintAKey),
    toMint: new PublicKey(mintBKey),
    quoteMint: undefined,
    fromWallet: userMintAAcc,
    toWallet: userMintBAcc,
    quoteWallet: undefined,
    fromMarket: market,
    toMarket: undefined,
    amount: amountBuffer,
    minExchangeRate: rateBuffer,
    referral: undefined,
    close: true,
    options: "confirmed",
    fromOpenOrders: openOrders.address,
    toOpenOrders: undefined,
  });
  let tx = txs[0].tx;
  let signers = txs[0].signers;
  let res = await signAndSendTransaction(connection, wallet, signers, tx);
  return res;
}

export async function getUserTokenBalance(connection, wallet, params) {
  // use mint
  let tokenList = await getTokenInfoList();
  let mintAKey = getToken2(tokenList, params.mintAName).address;
  let mintBKey = getToken2(tokenList, params.mintBName).address;
  // use account
  let walletAcc = wallet.publicKey;
  let mintABalance;
  let mintBBalance;
  if (mintAKey) {
    let res = await getTokenAccountMaxAmount(connection, wallet, mintAKey);
    if (res.code == 1) {
      mintABalance = res.data;
    } else {
      mintABalance = { acc: null, amount: 0.0, decimal: 0 };
    }
  } else {
    mintABalance = { acc: null, amount: 0.0, decimal: 0 };
  }
  if (mintBKey) {
    let res = await getTokenAccountMaxAmount(connection, wallet, mintBKey);
    if (res.code == 1) {
      mintBBalance = res.data;
    } else {
      mintBBalance = { acc: null, amount: 0.0, decimal: 0 };
    }
  } else {
    mintBBalance = { acc: null, amount: 0.0, decimal: 0 };
  }
  return {
    code: 1,
    msg: "get user token balance ok",
    data: { mintABalance, mintBBalance },
  };
}

export async function getOraclePrice(connection, params) {
  let mintAPrice = await getTokenPrice2(connection, params.mintAName);
  let mintBPrice = await getTokenPrice2(connection, params.mintBName);
  return {
    code: 1,
    msg: "get oracle price ok",
    data: {
      mintAPrice: mintAPrice.data,
      mintBPrice: mintBPrice.data,
    },
  };
}

<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :modal="true"
    :before-close="sendsmall"
  >
    <template slot="title">
      <div class="title">TRADE</div>
    </template>
    <el-row class="amount pc">
      <el-col :span="8">
        <div class="title">From</div>
        <el-select
          v-model="mintAName"
          @change="mintChange"
          class="selector"
          filterable
        >
          <el-option
            v-for="(item, index) in tokenOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="12">Price: ${{ mintAPrice.toFixed(3) }}</el-col>
        </el-row>
      </el-col>
      <el-col :span="15" :offset="1">
        <div class="title">Balance:{{ mintABalance }}</div>
        <el-input class="input" v-model="amount">
          <el-button slot="suffix" class="half" @click="calculateHalf"
            >HALF</el-button
          >
          <el-button slot="suffix" class="max" @click="calculateMax"
            >MAX</el-button
          >
        </el-input>
      </el-col>
    </el-row>
    <el-row class="amount mb">
      <el-col :span="13">
        <div class="title">From</div>
        <el-select
          v-model="mintAName"
          @change="mintChange"
          class="selector"
          filterable
        >
          <el-option
            v-for="(item, index) in tokenOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="price">Price: ${{ mintAPrice.toFixed(3) }}</div>
      </el-col>
      <el-col :span="10" :offset="1">
        <div class="title">Balance:{{ mintABalance.toFixed(3) }}</div>
        <el-input class="input" v-model="amount"></el-input>
      </el-col>
    </el-row>
    <div class="exchange-box">
      <img
        src="../assets/img/exchange.png"
        class="exchange"
        @click="onSwapMint"
      />
    </div>
    <el-row class="amount pc mt0" style>
      <el-col :span="8">
        <div class="title">To (estimated)</div>
        <el-select
          v-model="mintBName"
          @change="mintChange"
          class="selector"
          filterable
        >
          <el-option
            v-for="(item, index) in tokenOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="20"
            >Balance: {{ mintBBalance.toFixed(3) }} {{ mintBName }}</el-col
          >
        </el-row>
      </el-col>
      <el-col :span="15" :offset="1">
        <div class="title">Receive:</div>
        <div class="input el-input">
          <div class="el-input__inner">{{ mintBAmount.toFixed(6) }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row class="amount mb">
      <el-col :span="13">
        <div class="title">To (estimated)</div>
        <el-select
          v-model="mintBName"
          @change="mintChange"
          class="selector"
          filterable
        >
          <el-option
            v-for="(item, index) in tokenOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-row class="price">
          <el-col :span="20">Balance: ${{ mintBBalance.toFixed(3) }}</el-col>
        </el-row>
      </el-col>
      <el-col :span="10" :offset="1">
        <div class="title">Receive:</div>
        <div class="input el-input">
          <div class="el-input__inner">{{ mintBAmount.toFixed(6) }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row class="btn-box">
      <el-col :span="10" :offset="7">
        <el-button class="btn btn-c" @click="onSwap()" v-loading="loading">
          Swap Now
        </el-button>
      </el-col>
      <el-col :span="5" :offset="2">
        <el-button
          circle
          class="refresh"
          @click="refresh()"
          :class="{ loading: refreshing }"
        ></el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { Connection } from "@solana/web3.js";
import {
  getTokenInfoList,
  getSwap,
  loadSwapMarket,
  swap,
} from "../assets/js/serum/swap";
import { rpcConfig, rpcUrl } from "../assets/js";
import { oraclePublicKey, getTokenPrice2 } from "../assets/js/oracleList";
import { getTokenAccountMaxAmount } from "../assets/js/getBalance";
import { connection } from "../assets/js/vesting/utils";
import { getTokenPublicKey } from "../assets/js/token";

export default {
  props: ["small", "minta", "mintb"],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      refreshing: false,
      amount: 0,
      tolerance: 1.0,
      whiteList: [],
      mintAName: "",
      mintBName: "",
      tokenOptions: [],
      mintAPrice: 0.0,
      mintBPrice: 0.0,
      mintABalance: 0.0,
      mintBBalance: 0.0,
      dexPrice: 0.0,
      oraclePrice: 0.0,
      market: null,
      swap: null,
      connection: new Connection(rpcUrl, rpcConfig),
    };
  },
  async mounted() {
    this.getOptions2();
    if (this.$store.state.walletConnectInfo) {
      let wallet = this.$store.state.walletConnectInfo.wallet;
      this.swap = await getSwap(this.connection, wallet);
    }
  },
  methods: {
    sendsmall(done) {
      this.small("tradebiger");
      done();
    },
    async mintChange() {
      this.refreshing = true;
      // use account
      let wallet = this.$store.state.walletConnectInfo.wallet;
      // init data
      this.dexPrice = 0;
      this.mintAPrice = 0;
      this.mintABalance = 0.0;
      this.mintBBalance = 0.0;
      if (this.mintAName != "") {
        {
          let res = await getTokenPrice2(this.connection, this.mintAName);
          if (res.code == 1) {
            this.mintAPrice = res.data;
          } else {
            console.error("get mint a price error", res);
            this.$notify({
              title: "Error",
              message: "Get price error.",
              type: "error",
            });
          }
        }
        {
          let mintKey = getTokenPublicKey(this.mintAName);
          let res = await getTokenAccountMaxAmount(connection, wallet, mintKey);
          if (res.code == 1) {
            this.mintABalance = res.data.amount;
          }
        }
      }
      if (this.mintBName != "") {
        {
          let res = await getTokenPrice2(this.connection, this.mintBName);
          if (res.code == 1) {
            this.mintBPrice = res.data;
          } else {
            console.error("get mint b price error", res);
            this.$notify({
              title: "Error",
              message: "Get price error.",
              type: "error",
            });
          }
        }
        {
          let mintKey = getTokenPublicKey(this.mintBName);
          let res = await getTokenAccountMaxAmount(connection, wallet, mintKey);
          if (res.code == 1) {
            this.mintBBalance = res.data.amount;
          }
        }
      }
      if (this.mintAName != "" && this.mintBName != "") {
        let res = await loadSwapMarket(
          this.connection,
          this.swap,
          this.mintAName,
          this.mintBName
        );
        if (res.code == 1) {
          this.market = res.data.market;
          this.dexPrice = res.data.price;
        } else if (res.code == -11) {
          this.market = null;
          let msg =
            "between " + this.mintAName + " and " + this.mintBName + ".";
          this.$notify({
            title: "Warning",
            message: "There is no market " + msg,
            type: "warning",
          });
        } else {
          this.market = null;
          this.$notify({
            title: "Error",
            message: "Get market error.",
            type: "error",
          });
        }
      }
      this.refreshing = false;
    },
    calculateMax() {
      this.amount = this.mintABalance;
    },
    calculateHalf() {
      this.amount = this.mintABalance * 0.5;
    },
    async onSwap() {
      if (!this.loading) {
        if (!this.market) {
          this.$notify({
            title: "Warning",
            message: "There is no market.",
            type: "warning",
          });
          return;
        }
        if (!(this.amount > 0)) {
          this.$notify({
            title: "Warning",
            message: "Invalid amount.",
            type: "warning",
          });
          return;
        }
        this.loading = true;
        try {
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let params = {
            mintAName: this.mintAName,
            mintBName: this.mintBName,
            amount: this.amount,
            tolerance: this.tolerance,
          };
          let res = await swap(
            this.connection,
            wallet,
            this.swap,
            this.market,
            params
          );
          if (res.code == 1) {
            this.notifyWithSignature("Trade transaction confirmed", res.data);
          } else {
            console.error("swap error", res);
            this.$notify({
              title: "Error",
              message: "Trade error",
              type: "error",
            });
          }
        } catch (e) {
          console.error("Trade error", e);
          this.$notify({
            title: "Error",
            message: "Trade error",
            type: "error",
          });
        }
        this.loading = false;
      }
    },
    onSwapMint() {
      let mintANameBefore = this.mintAName;
      this.mintAName = this.mintBName;
      this.mintBName = mintANameBefore;
      this.refresh();
    },
    async refresh() {
      this.mintChange();
    },
    getOptions() {
      this.tokenOptions = oraclePublicKey.map((e) => {
        return { label: e.symbol, value: e.symbol };
      });
    },
    getOptions2() {
      let temp = getTokenInfoList();
      let tokenList = temp.getList();
      this.tokenOptions = tokenList.map((e) => {
        return { label: e.symbol, value: e.symbol, key: e.address };
      });
    },
    notifyWithSignature(msg, signature) {
      const h = this.$createElement;
      this.$notify({
        title: "Success",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("p", {}, msg),
          h("p", {}, [
            h(
              "a",
              {
                attrs: {
                  href: "https://explorer.solana.com/tx/" + signature,
                  target: "_blank",
                },
              },
              "view on explore"
            ),
            // h('a', { attrs: { href: 'https://explorer.solana.com/tx/' + signature + '?cluster=devnet', target: '_blank' } }, 'view on explore')
          ]),
        ]),
        type: "success",
      });
    },
  },
  computed: {
    mintBAmount() {
      if (this.mintBPrice > 0) {
        let mintAAmount = parseFloat(this.amount);
        if (!(mintAAmount >= 0)) {
          mintAAmount = 0;
        }
        let amount = mintAAmount * this.dexPrice;
        amount *= 1 - this.tolerance / 100;
        return amount;
      } else {
        return 0.0;
      }
    },
  },
  watch: {
    dialogVisible(newValue, oldValue) {
      if (newValue == true) {
        if (this.minta) {
          let mint = this.tokenOptions.find((e) => {
            return e.label == this.minta;
          });
          if (mint) {
            this.mintAName = mint.value;
          }
        }
        if (this.mintb) {
          let mint = this.tokenOptions.find((e) => {
            return e.label == this.mintb;
          });
          if (mint) {
            this.mintBName = mint.value;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mb {
  display: none;
}
.exchange-box {
  height: 15px;
  @media (max-width: 980px) {
    height: 5px;
  }
  position: relative;
  .exchange {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 11;
    cursor: pointer;
  }
}
.title {
  font-size: 20px;
  color: white;
  text-transform: uppercase;
}
.title2 {
  font-size: 18px;
  color: white;
}
.asset {
  font-size: 10px;
  color: white;
  background-color: #1b1c38;
  display: inline-block;
  padding: 3px 7px;
  border-radius: 3px;
}
.selector {
  margin-top: 15px;
  width: 100%;
  .el-input__inner {
    font-size: 14px;
    color: white;
    line-height: 40px;
    background-color: #1b1c3a;
  }
}
.price > *:nth-child(2) {
  text-align: right;
}
.amount {
  &.mt0 {
    margin-top: 0px;
  }
  margin-top: 15px;
  border: solid #2b1c46 2px;
  padding: 20px;
  border-radius: 10px;
  .title {
    font-weight: normal;
    text-transform: none;
  }
  .input {
    margin-top: 15px;
    .el-input__inner {
      font-size: 14px;
      color: white;
      line-height: 40px;
      background-color: #1b1c3a;
    }
    .half {
      background-color: transparent;
      font-size: 12px;
      padding: 0 5px;
      line-height: 40px;
      border: none;
      color: gray;
    }
    .max {
      background-color: transparent;
      font-size: 12px;
      padding: 0 20px;
      line-height: 40px;
      border: none;
      color: gray;
    }
  }
}
.unitmt20 {
  margin-top: 20px !important;
}
.tradetittle {
  color: transparent;
}
.fee {
  margin-top: 15px;
  .title {
    font-weight: normal;
    text-transform: none;
  }
  .unit {
    margin-top: 15px;
    padding: 15px;
    padding-top: 40px;
    border-radius: 10px;
    height: 100%;
    background: transparent;
    border: 2px solid #2b1c46;
    p {
      color: white;
      line-height: 25px;
      font-size: 18px;
    }
    .el-table {
      background-color: transparent;
      td,
      th {
        padding: 0;
      }
      td,
      th.is-leaf {
        border: none;
      }
      th,
      tr {
        background-color: transparent;
      }
      .el-table__body tr:hover > td {
        background-color: transparent;
      }
      .cell {
        font-size: 20px;
        line-height: 1.51;
        color: white;
      }
    }
  }
}
.balance {
  margin-top: 15px;
  color: white;
  & > span {
    font-weight: bold;
  }
}
.btn-box {
  margin-top: 15px;
  .text {
    font-size: 14px;
    color: white;
    white-space: nowrap;
    & > span {
      font-weight: bold;
    }
  }
  .btn {
    font-size: 14px;
    padding: 12px;
    position: relative;
    text-align: center;
    text-transform: none !important;
    transition: all 0.4s;
    &.disable {
      filter: grayscale(0) contrast(0.3);
      cursor: not-allowed;
    }
  }
  .refresh {
    height: 35px;
    width: 100%;
    background-color: transparent;
    background-position: center;
    background-image: url("../assets/img/refresh.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    border: none;
    &.loading {
      animation: 3s linear 0;
      animation-name: refreshing;
      animation-iteration-count: infinite;
      @keyframes refreshing {
        0% {
          transform: rotate(0turn);
        }
        50% {
          transform: rotate(0.5turn);
        }
        100% {
          transform: rotate(1turn);
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .pc {
    display: none;
  }
  .mb {
    display: block;
  }
  .ntitle {
    opacity: 1;
    line-height: 22px;
  }
  .exchange {
    right: 44%;
    top: 177px;
    height: 35px;
  }
  .el-dialog {
    width: 335px !important;
    background: #131226;
    border-radius: 10px;
    border-width: 4px;
    .el-dialog__header {
      padding: 15px 15px 5px 15px;
    }
    .el-dialog__body {
      padding: 5px 15px;
    }
    .amount {
      margin-top: 5px;
      .title {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .title {
    font-size: 16px;
    line-height: 22px;
  }
  .title2 {
    font-size: 12px;
  }
  .fee {
    .title {
      font-size: 14px;
      font-weight: normal;
    }
    .unit {
      padding: 5px;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 10px 5px;
      position: relative;
      p {
        display: initial;
        font-size: 14px;
        font-weight: normal;
        line-height: 34px;
      }
      .p2 {
        position: absolute;
        right: 10px;
      }
      .el-table {
        .cell {
          font-size: 12px;
          line-height: 17px;
          padding: 0;
        }
      }
    }
  }
  .btn-box {
    .btn {
      height: 40px;
      border-width: 1px;
      font-weight: normal;
      font-size: 16px;
    }
  }
}
</style>

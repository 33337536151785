import { Market } from "@project-serum/serum";
import { PublicKey } from "@solana/web3.js";

// local
// export const programId = "6NVQX4DKwF4k94Th4AiXy3htx4gbMzZAenGaH9y8Z6zz";
// devnet
// export const programId = "DESVgJVGajEgKGXhb6XmqDHGz3VjdgP7rEVESBgxmroY";
// mainnet
export const programId = "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin";
const programAcc = new PublicKey(programId);

export async function loadMarket(connection, marketKey) {
  let marketAcc = new PublicKey(marketKey);
  let marketOptions = { skipPreflight: true, commitment: "confirmed" };
  let market = await Market.load(
    connection,
    marketAcc,
    marketOptions,
    programAcc
  );
  if (market) {
    let res = await getMarketPrice(connection, market);
    if (res.code == 1) {
      return { code: 1, msg: "load market ok", data: { market, ...res.data } };
    }
  } else {
    return { code: 0, msg: "load market fail" };
  }
}

export async function getMarketPrice(connection, market) {
  let bids = await market.loadBids(connection);
  let asks = await market.loadAsks(connection);
  let bidPrice = bids.getL2(1);
  let askPrice = asks.getL2(1);
  let bidPrice1 = 1;
  if (bidPrice[0]) {
    bidPrice1 = bidPrice[0];
  }
  let askPrice1 = 1;
  if (askPrice[0]) {
    askPrice1 = askPrice[0];
  }
  return {
    code: 1,
    msg: "get market price ok",
    data: {
      bidPrice: bidPrice1,
      askPrice: askPrice1,
    },
  };
}

export async function findMarketByMints(connection, baseMintKey, quoteMintKey) {
  // use account
  let baseMintAcc = new PublicKey(baseMintKey);
  let quoteMintAcc = new PublicKey(quoteMintKey);
  // array of { publicKey, accountInfo}
  let res = await Market.findAccountsByMints(
    connection,
    baseMintAcc,
    quoteMintAcc,
    programAcc
  );
  if (res) {
    if (res.length > 0) {
      return { code: 1, msg: "find market ok", data: res };
    } else {
      return { code: 2, msg: "null market" };
    }
  } else {
    return { code: 0, msg: "find market fail" };
  }
}
